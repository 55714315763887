import React from "react";
import { Link } from "gatsby";
import Image from "./Images";
import {
  Container,
  ImageContainer,
  ContentsContainer,
  Title,
  SubTitle,
  Text,
  Wrapper,
  BannerImageMD,
  BannerImageSM,
  DotIcon,
  ListBox,
  StyledImg,
} from "./styles";
import PageContainer from "../PageContainer";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
import BannerImages from "../BannerImgs";

const ClearAligners = () => {
  const image = Image();
  return (
    <>
      <Breadcrumb pageTitle="">
        <BreadcrumbItem Link="/what-we-do" Text="What We Do" />
        <BreadcrumbItem
          Link="/what-we-do/clear-aligners"
          Text="Clear Aligners"
        />
      </Breadcrumb>
      <BannerImages imagesrc="clearAligners" />

      <PageContainer>
        <Container>
          <Title>Clear Aligners</Title>
          <Wrapper>
            <ContentsContainer>
              <Text>
                You are probably asking what is a clear aligner?
                <br /> Well, you may have heard of Invisalign which are one type
                of clear aligners. There are many other companies out there who
                do clear aligners.
                <br />
                And guess what... Dr Adam Wallace spent 3 years completing his
                Doctorate of Clinical Dentistry studying the absolute ins and
                outs of clear aligners (specifically, the Invisalign system of
                aligners). Therefore, he is the most trained and educated in the
                Gippsland area on Invisalign and clear aligner treatment.
              </Text>

              <SubTitle>How do clear aligners move teeth?</SubTitle>

              <Text>
                Clear aligners are used to move teeth and transform smiles. This
                treatment uses a series of custom-made clear aligners to
                gradually move your teeth into alignment. These aligners are
                virtually invisible, comfortable to wear and easy to remove.
                <br />
                <br />
                Each clear teeth aligner is individually manufactured for your
                teeth. It is worn for one week before moving onto the next
                aligner. As you replace each clear aligner, your teeth will move
                – little by little, week by week – until they have straightened
                to the final position prescribed by the Orthodontist. These
                trays are made from computer generated models and images of your
                teeth to ensure individualised fitting and customised treatment
                for your specific dental condition.
                <br /> The best bit ... clear aligners are removable, which
                means you can eat, drink and floss with ease.
              </Text>
            </ContentsContainer>
            <ImageContainer>
              <StyledImg fluid={image.clearAligners5} />
            </ImageContainer>
            <>
              <SubTitle>Are they for everyone?</SubTitle>

              <Text>
                You may have been thinking about straightening your teeth and
                fixing dental issues and came across clear aligner treatment.
                The advancement with aligners seems amazing for some patients
                who are desperately trying to avoid braces; however,
                unfortunately clear aligners are not suited for every patient.
              </Text>
              <Text>
                In many cases, aligner treatment can be used to treat
                orthodontic problems. For example, they can be used for mild to
                moderate dental issues, such as spacing or crowded teeth.
                However, there are some complicated cases that cannot be treated
                with aligners including complex bite or dental issues. The best
                way to find out if you are eligible to have clear aligner
                treatment is to get in contact with Gippsland Orthodontics and
                we would love to help determine if this is the right option for
                you!
              </Text>
              <SubTitle>Types of clear aligners?</SubTitle>
              <Text>
                At Gippsland Orthodontics we offer two types of clear aligner
                systems: Invisalign and Spark aligners.
              </Text>
              <Text>
                We begin the aligner process with a simple consultation, where
                our orthodontist examines your mouth and decides whether
                aligners are suitable for you. Our orthodontist will discuss the
                types of systems available, and the benefits and disadvantages
                of each aligner system.
              </Text>
              <Text>
                Once it has been decided to undergo Spark or Invisalign
                treatment, we will take 3D scans of your mouth. These scans will
                help create a treatment plan and virtual video showcasing your
                own new smile!
              </Text>
              <Text>
                If you are happy with the results you see, treatment can be
                underway.
              </Text>
              <Text>
                We make sure your Spark or Invisalign aligners are customised to
                you, ensuring a perfect fit and treatment to suit your own
                personal smile goals! If you have any questions regarding the
                types of clear aligners, or would like to book a consultation
                please reach out to Gippsland Orthodontics{" "}
                <Link to={`/contact`}>here</Link>.
              </Text>
            </>
          </Wrapper>
        </Container>
      </PageContainer>
    </>
  );
};

export default ClearAligners;
