import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import ClearAligners from "../../components/service-items/clear-aligners";
import { colors } from "../../styles/variables";

const ClearAlignersPage = () => (
  <Layout bgColor={colors.light}>
    <SEO title="Clear Aligners" />
    <ClearAligners />
  </Layout>
);

export default ClearAlignersPage;
